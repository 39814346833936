import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import NotFound from '~/components/NotFound/NotFound';

const getDisplayName = WrappedComponent =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component';

function withErrorHandling(WrappedComponent) {
  class WithErrorHandling extends React.Component {
    static async getInitialProps(context) {
      const result = await WrappedComponent.getInitialProps(context);

      const statusCode = context.isServer
        ? get(context, 'res.statusCode', 200)
        : get(context, 'statusCode', 200);

      return { statusCode, ...result };
    }

    render() {
      if (this.props.statusCode >= 400) {
        return <NotFound />;
      }
      return <WrappedComponent {...this.props} />;
    }
  }

  WithErrorHandling.displayName = `WithErrorHandling(${getDisplayName(WrappedComponent)})`;

  WithErrorHandling.propTypes = {
    statusCode: PropTypes.number.isRequired,
  };

  return WithErrorHandling;
}

export default withErrorHandling;
