import React from 'react';
import { operations } from '~/state/userAgent';

const getDisplayName = WrappedComponent =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component';

function withUserAgent(WrappedComponent) {
  class WithUserAgent extends React.Component {
    static async getInitialProps(context) {
      const userAgent = context.isServer ? context.req.headers['user-agent'] : navigator.userAgent;
      context.store.dispatch(operations.storeUserAgent(userAgent));

      return WrappedComponent.getInitialProps(context);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  WithUserAgent.displayName = `WithUserAgent(${getDisplayName(WrappedComponent)})`;
  return WithUserAgent;
}

export default withUserAgent;
