/* eslint-disable */

import React from 'react';
import { IntlProvider, addLocaleData, injectIntl } from 'react-intl';

interface Amplitude {
  setUserProperties: Function;
}
interface IntlWindow extends Window {
  ReactIntlLocaleData: Object;
  amplitude: Amplitude;
  __NEXT_DATA__: { props: any };
}

declare var window: IntlWindow;

interface Props {
  locale: string;
  messages: string[];
  now: Date;
}
// Register React Intl's locale data for the user's locale in the browser. This
// locale data was added to the page by `pages/_document.js`. This only happens
// once, on initial page load in the browser.
if (typeof window !== 'undefined' && window.ReactIntlLocaleData) {
  Object.keys(window.ReactIntlLocaleData).forEach(lang => {
    addLocaleData(window.ReactIntlLocaleData[lang]);
  });
}

const getDisplayName = wrappedComponent =>
  wrappedComponent.displayName || wrappedComponent.name || 'Component';

const WithIntl = Page => {
  const IntlPage = injectIntl(Page);

  return class PageWithIntl extends React.Component<Props> {
    static displayName = `withIntl(${getDisplayName(Page)})`;
    static async getInitialProps(context) {
      let props;

      // Get the `locale` and `messages` from the request object on the server.
      // In the browser, use the same values that the server serialized.

      const { req } = context;
      const { locale, messages } = req || window.__NEXT_DATA__.props.pageProps;

      if (typeof Page.getInitialProps === 'function') {
        props = await Page.getInitialProps({ locale, ...context });
      }

      // Always update the current time on page load/transition because the
      // <IntlProvider> will be a new instance even with pushState routing.
      const now = Date.now();

      return { ...props, locale, messages, now };
    }

    componentDidMount() {
      if (typeof window !== 'undefined' && window.amplitude) {
        window.amplitude.setUserProperties({
          locale: this.props.locale,
        });
      }
    }

    render() {
      const { locale = 'en', messages, now, ...props } = this.props;

      return (
        <IntlProvider locale={locale} messages={messages} initialNow={now}>
          <IntlPage {...props} />
        </IntlProvider>
      );
    }
  };
};

// tslint:disable:variable-name
export default WithIntl;
