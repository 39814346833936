import PropTypes from 'prop-types';
import React from 'react';
import StyledComponent from '~/components/Styled/Styled';
import Layout from '~/components/Layout/Layout';
import Link from '~/components/Link/Link';
import Button from '~/components/Button/Button';
import { connect } from 'react-redux';

import find from 'lodash/find';

import css from './NotFound.scss';

export class NotFound extends React.Component {
  getRoute(slug) {
    const league = find(this.props.menu.all, s => s.slug === slug) || { slug: 'top_news' };
    return league.slug === 'top_news' ? `/${league.slug}` : `/${league.slug}/news`;
  }

  getButtonCopy(slug) {
    const league = find(this.props.menu.all, s => s.slug === slug) || { slug: 'top_news' };
    return league.slug === 'top_news'
      ? 'Return to Home'
      : `Return to ${league.slug.toUpperCase()} News`;
  }

  render() {
    return (
      <Layout>
        <div className={css.cardContainer}>
          <div className={css.messageContainer}>
            <img className={css.mainImage} src="/static/vectors/404.svg" alt="Not found" />
            <div className={css.copy}>
              <span className={css.title}>Page Not Found</span>
              <div className={css.message}>Oops, looks like something went wrong!</div>
            </div>
            <Link route={this.getRoute(this.props.leagueSlug)}>
              <a className={css.button}>
                <Button
                  width="156px"
                  height="38px"
                  copy={this.getButtonCopy(this.props.leagueSlug)}
                />
              </a>
            </Link>
          </div>
        </div>
      </Layout>
    );
  }
}

NotFound.propTypes = {
  leagueSlug: PropTypes.string,
  menu: PropTypes.shape({
    all: PropTypes.array,
  }),
};

NotFound.defaultProps = {
  leagueSlug: 'top_news',
  menu: {
    all: [],
  },
};

const mapStateToProps = state => ({
  menu: state.menuData.data,
});

export default connect(mapStateToProps)(StyledComponent(NotFound, [css]));
