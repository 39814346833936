exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NotFound__messageContainer--1VqIP {\n  text-align: center;\n}\n\n.NotFound__message--3X6Yd {\n  margin-top: 16px;\n  font-size: 14px;\n  font-weight: normal;\n  color: rgba(255, 255, 255, 0.65);\n  line-height: 21px;\n}\n\n.NotFound__cardContainer--3uARg {\n  margin: 100px auto 30px;\n}\n\n.NotFound__mainImage--2qV0r {\n  width: 152px;\n}\n\n.NotFound__copy--3Xpm8 {\n  margin: 24px 0;\n}\n\n.NotFound__title--3N3Cc {\n  display: block;\n  margin-bottom: 16px;\n  font-size: 18px;\n  font-weight: 700;\n}\n\n.NotFound__button--2jp8Z {\n  outline: none;\n  display: inline-block;\n  border-radius: 4px;\n}\nbody.user-is-tabbing .NotFound__button--2jp8Z:focus {\n  box-shadow: 0 0 0 2px #fff;\n}\n\n@media (min-width: 768px) {\n  .NotFound__mainImage--2qV0r {\n    width: 165px;\n  }\n  .NotFound__copy--3Xpm8 {\n    margin-top: 34px;\n    margin-bottom: 32px;\n  }\n  .NotFound__title--3N3Cc {\n    font-size: 22px;\n  }\n  .NotFound__message--3X6Yd {\n    font-size: 16px;\n    line-height: 22px;\n  }\n}", ""]);

// exports
exports.locals = {
	"messageContainer": "NotFound__messageContainer--1VqIP",
	"message": "NotFound__message--3X6Yd",
	"cardContainer": "NotFound__cardContainer--3uARg",
	"mainImage": "NotFound__mainImage--2qV0r",
	"copy": "NotFound__copy--3Xpm8",
	"title": "NotFound__title--3N3Cc",
	"button": "NotFound__button--2jp8Z"
}; 
var style = module.exports.toString();module.exports = exports.locals || { stylesheet: "" };if (!module.exports.stylesheet) { module.exports.stylesheet = style; };