import React from 'react';
import { operations } from '~/state/utmSource';

const getDisplayName = WrappedComponent =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component';

function withUtmSource(WrappedComponent) {
  class WithUtmSource extends React.Component {
    static async getInitialProps(context) {
      const utmSource = context.store.getState().utmSource.data;
      if (context.store && typeof utmSource === 'undefined') {
        context.store.dispatch(operations.setUtmSource(context.query.utm_source));
      }

      return WrappedComponent.getInitialProps(context);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  WithUtmSource.displayName = `WithUtmSource(${getDisplayName(WrappedComponent)})`;
  return WithUtmSource;
}

export default withUtmSource;
