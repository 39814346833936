import React from 'react';
import experiments from '~/experiments/';
import uuid from 'uuid';
import Cookies from 'js-cookie';
import { operations } from '~/state/abTest';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import isMobileFunc from '~/util/isMobile';
import PropTypes from 'prop-types';

const getId = id => {
  if (id) return id;
  return uuid();
};

const getDisplayName = WrappedComponent =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component';

const getExperiments = () => {
  if (typeof window === 'undefined') return [];

  const experimentNames = Object.keys(experiments);
  const experimentData = experimentNames.map(exp => {
    const experimentParams = experiments[exp].getParams(name) || {};
    return {
      experimentParams,
      active: experiments[exp].inExperiment() || false,
      experimentObj: experiments[exp],
    };
  });
  return experimentData;
};

function withExperiment(WrappedComponent) {
  class WithExperiment extends React.Component {
    static getInitialProps(context) {
      return WrappedComponent.getInitialProps(context);
    }

    componentWillMount() {
      if (typeof window === 'undefined') return;

      const userIdCookie = Cookies.get('ab-userIdentifier');
      const userId = getId(userIdCookie);
      Cookies.set('ab-userIdentifier', userId, { expires: 791 });

      if (Cookies('interstitialSeen')) this.props.setInterstitialSeen();
      if (Cookies('alertSeen')) this.props.setAlertSeen();
      this.props.setMobile(isMobileFunc(navigator.userAgent));
      this.props.setId(userId);
      this.props.setExperiments(getExperiments());
    }

    render() {
      return <WrappedComponent {...this.props} experiments={getExperiments()} />;
    }
  }

  WithExperiment.propTypes = {
    setExperiments: PropTypes.func,
    setId: PropTypes.func,
    setMobile: PropTypes.func,
    setInterstitialSeen: PropTypes.func,
    setAlertSeen: PropTypes.func,
  };

  WithExperiment.defaultProps = {
    setExperiments: () => {},
    setId: () => {},
    setMobile: () => {},
    setInterstitialSeen: () => {},
    setAlertSeen: () => {},
  };

  WithExperiment.displayName = `WithExperiment(${getDisplayName(WrappedComponent)})`;

  const mapDispatchToProps = dispatch => ({
    setId: bindActionCreators(operations.setId, dispatch),
    setExperiments: bindActionCreators(operations.setExperiments, dispatch),
    setMobile: bindActionCreators(operations.setMobile, dispatch),
    setInterstitialSeen: bindActionCreators(operations.setInterstitialSeen, dispatch),
    setAlertSeen: bindActionCreators(operations.setAlertSeen, dispatch),
  });

  return connect(null, mapDispatchToProps)(WithExperiment);
}

export default withExperiment;
