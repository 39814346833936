import compose from 'recompose/compose';
import React from 'react';

import withRedux from 'next-redux-wrapper';
import withExperiment from './withExperiment';
import withPageData from './withPageData';
import withUtmSource from './withUtmSource';
import withErrorHandling from './withErrorHandling';
import withIntl from './withIntl';
import withUserAgent from './withUserAgent';

const WithState = (store, state, dispatch) => {
  let makeStore;
  let mapStateToProps;
  let mapDispatchToProps;

  if (typeof store === 'object') {
    makeStore = store.createStore;
    mapStateToProps = store.mapStateToProps;
    mapDispatchToProps = store.mapDispatchToProps;
  } else {
    makeStore = store;
    mapStateToProps = state;
    mapDispatchToProps = dispatch;
  }

  return WrappedComponent => {
    class WithStateComponent extends React.Component {
      static async getInitialProps(context) {
        return WrappedComponent.getInitialProps(context);
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    return compose(
      withIntl,
      withRedux(makeStore, mapStateToProps, mapDispatchToProps),
      withExperiment,
      withPageData,
      withUserAgent,
      withUtmSource,
      withErrorHandling,
      withIntl
    )(WithStateComponent);
  };
};

export default WithState;
